import { Component } from '@angular/core';
import { StockChartsBaseComponent } from '../base/stockcharts-base.component';
import { MasterDetailChartConfig } from '../configs';

@Component({
	selector: 'lab-master-detail',
	template: `
		<div [chart]="chart"></div>
	`
})

export class MasterDetailStockChartComponent extends StockChartsBaseComponent  {

	constructor() {
		super();
	}

	ngAfterViewInit(){
		this.config = this.config ? this.config : new MasterDetailChartConfig().getConfig(null);
		super.ngAfterViewInit();
	}
}
