import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboBase } from '../base';
import { MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';


@Component({
	selector: 'lab-combo',
	template: `<div class="md-form">
	<input
		#input
		mdbInputDirective
		id="{{id}}{{label}}"
		type="text"
		class="completer-input form-control mdb-autocomplete border-dark text-dark"
		[(ngModel)]="searchText"
		[disabled]="disabled"
		[ngClass]="{
			'border-light': disabled,
			'text-white': !disabled && whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'border-white': !disabled && whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'text-dark': !disabled && !whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'border-dark': !disabled && !whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'shadow-dark': !disabled && (isFormControlValid() && formControl.pristine) && focused,
			'text-info': !disabled && (!isFormControlValid() && inputHasFocus()) || (inputHasFocus() && isFormControlValid() && formControl.pristine),
			'border-info': !disabled && (!isFormControlValid() && inputHasFocus()) || (inputHasFocus() && isFormControlValid() && formControl.pristine),
			'shadow-info': !disabled && (!isFormControlValid() && inputHasFocus()) || (inputHasFocus() && isFormControlValid() && formControl.pristine),
			'text-success': !disabled && isFormControlValid() && formControl.status === 'VALID' && !formControl.pristine,
			'border-success': !disabled && isFormControlValid() && formControl.status === 'VALID' && !formControl.pristine,
			'shadow-success': !disabled && isFormControlValid() && formControl.status === 'VALID' && focused && !formControl.pristine,
			'text-danger': !disabled && isFormControlValid() && formControl.status === 'INVALID' && !formControl.pristine,
			'border-danger': !disabled && isFormControlValid() && formControl.status === 'INVALID' && !formControl.pristine,
			'shadow-danger': !disabled && isFormControlValid() && formControl.status === 'INVALID' && focused && !formControl.pristine
		}"
		(input)="getFilteredData()" 
		(ngModelChange)="onChange($event)"
		[mdbAutoCompleter]="auto"
	>

	<label 
		for="{{id}}{{label}}"
		[ngClass]="{ 'border-light': disabled }"
		class="text-dark">{{label}}</label>

	<mdb-auto-completer #auto textNoResults="I have found no results :(" >
		<mdb-option *ngFor="let option of searchEntries(searchText) | async" [value]="option.name"> 
			<div class="row w-100 bg-transparent">
				<div class="col-lg-3 col-sm-4 small bg-transparent" *ngIf="option && option.id && checkIdUsage(optionUsage)">
					{{ option.id }}
				</div>
				<div class="col small truncate-text bg-transparent"  *ngIf="option && option.name && checkNameUsage(optionUsage)" mdbTooltip="{{option.name}}" placement="top" container="body">
					{{ option.name }}
				</div>
			</div>
		</mdb-option>
	</mdb-auto-completer>

</div>`,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: ComboComponent, multi: true}
	]
})
export class ComboComponent<T> extends ComboBase<T>{

	amountToShow = 100;
	
	@Input() source: any;
	@Input() label: string;
	@Input() iconEnd: string;
	@Input() whiteInput: boolean = false;
	@Input() deactivateLabelAnimation: boolean = false;
	@Input() iconStart: string;
	@Input() autofocus = false;
	@Input() description: string = null;
	@Input() disabled: boolean = false
	@Input() optionUsage: 'id' | 'name' | 'both' = 'both'

	@ViewChild('input') input: ElementRef;
	@ViewChild(MdbAutoCompleterComponent) completer: MdbAutoCompleterComponent;

	checkIdUsage(prop: 'id' | 'name' | 'both'){ return prop === 'id' || prop === 'both' }

	checkNameUsage(prop: 'id' | 'name' | 'both'){ return prop === 'name' || prop === 'both' }

	constructor() {
		super();
	}

	
	ngAfterViewInit() { this.subscribeToOptionSelected(); }
	
	getDataItems() { return this.source; }
	
	getFilteredData() { 
		if(this.getDataItems() && !this.disabled)
			this.results = this.searchEntries(this.searchText);
	}
	
	onChange(change: any) { this.getFilteredData();  }
	
	searchEntries(term: string): Observable<T>{
		return new Observable((observer) => {
			let filter = this.getDataItems() && term.length > 0 ? this.getDataItems().filter((data: any) =>
				(data && data.id && (''+data.id).includes(''+term)) 
			||	(data && data.name && data.name.toLowerCase().includes(''+term.toLowerCase())) ) : [];

			observer.next(
				term && term.length > 0 && this.getDataItems() && filter ? 
				filter.slice(0, this.amountToShow) : 
				term != null && term.length == 0 && this.getDataItems() ? this.getDataItems().slice(0, this.amountToShow) : []);
		})
	}

	subscribeToOptionSelected(): void {
		this.completer.selectedItemChanged().subscribe((data: any) => {
			if(data.text) {		
				this.searchText = data.text;
				this.source.map(x => {
					if(x.name === data.text || x.id === data.text)
						this.value = x;
				})
			}
			this.getFilteredData();
		});
	}

}
