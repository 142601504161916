import { Component, Input, EventEmitter, Output } from '@angular/core';
import { HighchartsBaseComponent } from '../base/highcharts-base.component';
import { SpiderwebChartConfig } from '../configs/spiderwebChartConfig.model';

@Component({
	selector: 'lab-spiderweb',
	template: `
		<lab-highcharts>
			<div content class="chart" id="{{uuid}}" #chartView></div>
		</lab-highcharts>
	`
})

export class SpiderWebChartComponent extends HighchartsBaseComponent  {

	@Output() pointClicked = new EventEmitter()
	@Input() categories = []

	constructor() {
		super();
	}

	setSeries(series: any): Array<Highcharts.IndividualSeriesOptions> {
		return series;
	}
	ngAfterViewInit(){
		this.config = this.config ? { ...this.config } : new SpiderwebChartConfig().getSpiderWebConfig("100%");
		this.config.plotOptions.series.point.events.click = (v: any) => { 
			this.pointClicked.emit(v.point.category);
			return false;
		}
		this.config.chart.events = { click: (e: any) => {
			this.pointClicked.emit(e.target.innerText) 
		}}

		this.config.xAxis.categories = this.categories;
		super.ngAfterViewInit();
	}

}