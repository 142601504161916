import { Component, Input, AfterViewInit, OnInit, ViewChild, ElementRef, Output, EventEmitter, HostListener, OnChanges, NgZone, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { TableListBase, HeaderDescriptor, GroupDescriptor } from '../../models';
import { TableConfigComponent } from './config/table-config.component';
import * as _ from 'lodash';
import {  ColumnModel } from '../../models/table/column.model';
import {  RowModel } from '../../models/table/row.model';

@Component({
  selector: 'lab-table',
  template: `<div outSideEventHandler (scroll)="detectChanges()" class="table-responsive position-relative" style="max-height: inherit; background-color: #f2f2f2;" #tableContainer>

	<lab-table-config
		*ngIf="changedHeaders && configOpened"
		[isOpened]="configOpened"
		(isOpenedChange)="configureTable()"
		#tableConfig
		[filterSearch]="filterSearch" 
		[groupedHeaders]="changedHeaders"
		class="bg-white"
		[ngClass]="{'d-none': !configOpened}">

		<div filter>
			<ng-content select=[search-filter]></ng-content>
		</div>

	</lab-table-config>

	<table class="table table-sm mb-0 position-relative table-striped" small="true" id="table-1"  style="max-height: 100%;" [hidden]="configOpened">

		<thead *ngIf="multiLevelHeaders" class="text-center bg-white">
			<tr #multiLevelHeader></tr>
		</thead>
		
		<thead [ngClass]="{'py-1': multiLevelHeaders }">
			<tr>	
				<th scope="col" 
					class="position-relative configHeader border-right border-left" 
					*ngIf="!basicTable"
					[ngClass]="{'py-1': multiLevelHeaders}">
					<span *ngIf="headersConfig">

						<div
							(click)="configureTable()"
							class="cursor-pointer text-center"  
							[ngClass]="{'configIcon-absolute': multiLevelHeaders}">

							<i class="icon-lab-config">
								<input type="checkbox" [(ngModel)]="configOpened" id="configOpened" class="d-none">
							</i>
						</div>
	
					</span>
				</th>
				
				<th scope="col"
					class="pl-2 cursor-pointer border-right capitalize"
					style="width: calc(100% + 10px)" 
					*ngFor="let header of usedHeaders" 
					[ngClass]="{'py-1': multiLevelHeaders, 'border-right-0 text-center': header.icon && header.name.length === 0}">
					<span class="small truncate-text" mdbTooltip="{{header.description}}" placement="top" container="body" *ngIf="!(header.name.length === 0)" (click)="sort(header)" >
						{{header.name }}{{ sortingEntity === header ? '&nbsp;&nbsp;&nbsp;' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }}
						<i class=" pr-1 fa text-dark" [ngClass]="{'fa-sort-desc': isCrescentOrder && (sortingEntity == header), 'fa-sort-asc': !isCrescentOrder && (sortingEntity == header)}" aria-hidden="true"></i> 
					</span>
				</th>
			</tr>
		</thead>

		<tbody>
			
			<tr 
				class="cursor-pointer w-100" 
				style="height: 43px;" 
				*lazyFor="let row of usedRows;" 
				[ngClass]="{'selected': row.isSelected }">
					
				<th 
					*ngIf="!basicTable"	
					scope="row"
					(click)="edit(row)" 
					class="border border-bottom-0 border-left-0 position-relative configHeader border-left">
					
					<i class="{{firstColumnIcon}} table-icon" *ngIf="firstColumnIcon && headersConfig"></i>

					<div *ngIf="haveFirstColumn">
						<div class="custom-control custom-checkbox position-relative">
							<input type="checkbox" disabled [checked]="row.isSelected" class="custom-control-input" id="defaultUnchecked{{row.name}}">
							<label class="custom-control-label checkbox-config" for="defaultUnchecked{{row.name}}"></label>
						</div>
					</div>
				</th>
				
				<td 
					(click)="selectRow(row, column)"
					*ngFor="let column of row.columns; trackBy: track"
					[ngClass]="{ 
						'text-left': column.alignment === 'left' && column.valueIsNumber == false,
						'text-center': column.alignment === 'center', 
						'text-right': column.alignment === 'right' || column.valueIsNumber
					}" 
					class="border-bottom-0 border-right truncate-text">
				
					<div class="text-center">
						<i 
							*ngIf="column.icon"
							class="{{column.value.icon}}" 
							placement="top"
							container="body" 
							mdbTooltip="{{column.value.description}}"
							(click)="checkRowColumnClick(column, row)" 
							></i>
					</div>

					<span
						*ngIf="(!row.isSelected || !column.editable) && !column.icon"
						class="small"
						(click)="checkRowColumnClick(column, row)" 
						[innerHTML]="column.value | highlight: searchString"></span>

					<div 
						*ngIf="row.isSelected && column.editable"
						class="position-relative no-pointer-event" >

						<div class="row text-right">
							<div class="col-12 position-relative">

								<input
									*ngIf="!column.typeOfEditonIsNumber"
									class="postion-absolute border border-muted no-input-outline no-pointer-event small w-100"
									type="text" 
									lang="pt-BR"
									[ngClass]="{'text-right': column.typeOfEditonIsNumber}"
									[ngModel]="column.value" 
									(ngModelChange)="setNewValue(row, column, $event)">

								<input
									*ngIf="column.typeOfEditonIsNumber"
									class="postion-absolute border border-muted no-input-outline no-pointer-event small w-100"
									type="number" 
									lang="pt-BR"
									[ngClass]="{'text-right': column.typeOfEditonIsNumber}"
									[ngModel]="column.value" 
									(ngModelChange)="setNewValue(row, column, $event)">
							</div>
						</div>

					</div>
				</td>
			</tr>

		</tbody>
	</table>
</div>

`
})
export class TableComponent<T> implements AfterViewInit, OnInit, OnChanges, OnDestroy {


	public editedEntityValue = '';
	public haveScrollBar = false;
	
	@Input() uuid = 'table';
	@Input() basicTable: boolean = true;
	@Input() list: TableListBase<T>;
	@Input() selectedColor: string;
	@Input() singleSelection: boolean = false;
	@Input() filterSearch: string;
	@Input() headersConfig: boolean = false;
	@Input() headerEvent: string = null;
	@Input() multiLevelHeaders: boolean = false;
	@Input() searchString = '';
	@Input() tableBodyHeight: number;
	@Input() onTableEdit = false;
	@Input() firstColumnIcon: boolean = true;
	@Input() firstColumnTooltip: string;

	@Output() selectedItem = new EventEmitter<any>();
	@Output() onIconClicked = new EventEmitter<T>();

	@ViewChild('multiLevelHeader') multiLevelHeader: ElementRef;
	@ViewChild('tableConfig') tableConfig: TableConfigComponent<any>;

	@HostListener('window:beforeunload') storeHeaders(){ localStorage.setItem(this.uuid, JSON.stringify(this.list.groupHeaders)); }

	isCrescentOrder: boolean = null;
	haveFirstColumn: boolean = false;
	sortingEntity: HeaderDescriptor<T> = null;
	configOpened: boolean = false;
	changedHeaders: Array<GroupDescriptor<T>>;
	usedHeaders: Array<HeaderDescriptor<T>>;
	usedRows: Array<RowModel> = [];
	lastModifiedRow: {entity: T, header: HeaderDescriptor<T>, column: ColumnModel} = null;

	filteredEntities: Array<T>;

	constructor(private cdr: ChangeDetectorRef) { 
	}

	track (index, item) {
		return index;
	}

	ngOnDestroy(): void {
		if(this.list && this.list.groupHeaders)
			this.storeHeaders();
	}

	ngAfterViewInit(): void {
		this.haveFirstColumn = (!this.firstColumnIcon && this.headersConfig) || !this.headersConfig;
		this.usedHeaders = this.getUsedHeaders();
		this.showHeaders();
		this.filter();
		this.getUsedRows();
		this.cdr.detectChanges();
	}

	ngOnChanges(){
		this.filter();
		this.cdr.detectChanges();
	}

	rebuildTable(){
		this.usedHeaders = this.getUsedHeaders();
		this.getUsedRows();
	}

	@Output()
	public detectChanges(){ this.cdr.detectChanges(); }

	entityTrack(index, item){ return index; }

	configureTable(){
		this.configOpened = !this.configOpened;
		
		if(!this.changedHeaders){
			this.usedHeaders = [];
			this.changedHeaders = _.cloneDeep(this.list.groupHeaders);
		}
		
		if(!this.configOpened && this.tableConfig){
			this.list.groupHeaders = _.cloneDeep(this.tableConfig.groupedHeaders);
			this.rebuildTable();
			this.showHeaders();
		}
		
		this.storeHeaders()
		this.cdr.detectChanges();
	}

	ngOnInit() { 
		this.cdr.detach();
		this.getStorageHeaders(); 
	}

	getStorageHeaders() { 
		const fromStorage = JSON.parse(localStorage.getItem(this.uuid));
		if(!fromStorage) return;
 
		fromStorage.map((grp) => {
			const group = this.list.groupHeaders.find(x => x.group == grp.group);
			if(group){
				group.headers.map(head => {
					const header = grp.headers.find(x => x.name === head.name);
					if(header)
						head.onUse = header.onUse;
					else
						head.onUse = false;

				});
			}
		});
	}

	getUsedHeaders(){
		const headers = [];
		if(!this.basicTable)
			this.list.groupHeaders.map((g) => {
				headers.push(...g.headers.filter(y => y.onUse || y.fixed));
			});
		else
			this.list.groupHeaders.map((g) => {
				headers.push(...g.headers);
			});
		return headers;
	}


	getUsedRows(){
		let rows: Array<RowModel> = [];
		let selectedIds: any = this.usedRows.filter(r => r.isSelected ).map(x => x.id);

		this.filteredEntities.map(f => {
			let row = new RowModel();
			row.id = this.list.rowIndentificator(f);
			row.isSelected = selectedIds.indexOf(row.id) >= 0;

			this.usedHeaders.map(u => {
				let column = new ColumnModel();

				column.name = u.name;
				column.description = u.description;
				column.value = u.getter(f);
				column.valueIsNumber = !Number.isNaN(+(''+column.value).split('.').join('').split(',').join(''));
				column.setter = u.setter;
				column.icon = u.icon;
				column.onUse = true;
				column.click = u.click ? u.click : null;
				column.fixed = u.fixed;
				column.editable = u.editable;
				column.typeOfEditon = !u.editable ? null : (<any>u.editable).number ? 'number' : 'text';
				column.typeOfEditonIsNumber = column.typeOfEditon === 'number' ? true : false;
				column.alignment = u.alignment;
				
				row.columns.push(column);
			});
			rows.push(row);
		});

		this.usedRows = rows;	
	}

	selectRow(row: RowModel, column: ColumnModel){
		if(this.singleSelection && !row.isSelected)
			this.usedRows.map(r => r.isSelected = false);

		if(!(column && column.editable && row.isSelected) && !this.basicTable)
			row.isSelected = !row.isSelected;

		if(row.hasChanges){
			row.isSelected = false;
			row.hasChanges = false;
			this.getUsedRows();
		}

		const selectedItems = this.usedRows.filter(r => r.isSelected).map(r => this.getAssociatedEntity(r));
		this.selectedItem.emit(
			{selectedEntitites: selectedItems, toggledEntity: this.getAssociatedEntity(row)}
		);
		this.list.selectedEntities = selectedItems;

		if(!row.isSelected)
			this.lastModifiedRow = null;

		this.lastModifiedRow = <any>{};
		this.cdr.detectChanges();
	}
	
	selectAllItems(){
		this.usedRows.map(r => r.isSelected = true);
		const selectedItems = this.usedRows.filter(r => r.isSelected).map(r => this.getAssociatedEntity(r));
		this.selectedItem.emit(
			{selectedEntitites: selectedItems, toggledEntity: null}
		);
		this.list.selectedEntities = selectedItems;
		this.cdr.detectChanges();

	}

	unselectAllItems(){
		this.usedRows.map(r => r.isSelected = false);
		this.selectedItem.emit(
			{selectedEntitites: [], toggledEntity: null}
		);
		this.list.selectedEntities = [];
		this.cdr.detectChanges();

	}

	getAssociatedEntity(row: RowModel): T{ return this.filteredEntities[this.usedRows.indexOf(row)]; }

	getAssociatedHeader(column: ColumnModel): HeaderDescriptor<T> { return this.usedHeaders.find(h => h.name === column.name && h.description === column.description); }

	checkRowColumnClick(column, row){
		if(column.click) 
			column.click(this.getAssociatedEntity(row))
	}

	setNewValue(row: RowModel, column: ColumnModel, event: any){
		if(!this.lastModifiedRow || (this.lastModifiedRow && this.lastModifiedRow.column !== column)){
			let header = this.getAssociatedHeader(column);
			let entity = this.getAssociatedEntity(row);
			this.lastModifiedRow = {entity, column, header};
			row.hasChanges = true;
		}

		this.lastModifiedRow.header.setter(this.lastModifiedRow.entity, column.typeOfEditonIsNumber ? +event : event);
	}

	public filter(): void {
		if(this.list && this.list.entities && this.searchStringIsValid()){
			const filteredEntitys = this.list ? this.list.filter(this.searchString) : [];

			if(filteredEntitys.length === 0)
				this.filteredEntities =  [];

			this.filteredEntities = filteredEntitys.length === 0 ? this.list.entities : filteredEntitys;
			this.getUsedRows();

		} else {
			this.filteredEntities = this.list.entities;
		}
		this.cdr.detectChanges();
	}

	public sort(header: HeaderDescriptor<T>) {
		this.sortingEntity = header;
		this.filteredEntities.sort((a, b) => {
			const valueA = this.getPropertyValueToSort(a, header);
			const valueB = this.getPropertyValueToSort(b, header);
			return this.compareValuesToSort(valueA, valueB, this.isCrescentOrder);
		});
		this.isCrescentOrder = !this.isCrescentOrder;
		this.getUsedRows();
		this.cdr.detectChanges();
	}

	private getPropertyValueToSort(entity: T, header: HeaderDescriptor<T>) {
		const value: string = this.getDataBetweenTags(header.getter(entity));
		return this.isString(value) ? value.toLowerCase().noSpecials() : ''+value.toLowerCase().noSpecials();
	}
	
	private compareValuesToSort(a: any, b: any, isCrescent: boolean) {
		if (isCrescent)
			return (a < b) ? -1 : 1;
		else
			return (a > b) ? -1 : 1;
	}
	
	private getDataBetweenTags(data: string){
		let n = data+'';
		while(n.indexOf('<') >= 0 && n.indexOf('>') >= 0)
			n = n.split('<')[1].split('>')[1];
		return n;
	}

	public checkIfIsSelected(entity: T): boolean { 
		return this.list.selectedEntities ? this.list.selectedEntities.indexOf(entity) >= 0 : false; 
	}

	public checkIfSingleSelection(entity: T){
		if(this.singleSelection && !this.list.selectedEntities.find(e => e === entity))
			this.list.selectedEntities = [];
	}

	public edit(row: RowModel) {
		this.onIconClicked.emit(this.getAssociatedEntity(row));

		if(!row.isSelected)
			this.selectRow(row, null);
	}

	private isString(value: any) { return (typeof value === 'string'); }
	
	public dataIsNumber(str: string){ return !isNaN(Number(str)) }

	public searchStringIsValid(): boolean { return this.searchString !== null && this.searchString !== undefined && this.searchString.length > 0; }

	private showHeaders(){
		if(this.multiLevelHeader){
			let innerThead = !this.basicTable ? `<th class="${this.multiLevelHeaders ? 'py-1' : 'py-0'} truncate-text configHeader border-bottom-0 border-right  border-left border-top"></th>` : '';
			if(this.list.groupHeaders.length > 0){
				for(let headersGroup of this.list.groupHeaders){
					const haveUsingHeaders = headersGroup.headers.filter(x => x.onUse || x.fixed).length;
					if(haveUsingHeaders > 0)
					innerThead += `<th colspan="${haveUsingHeaders}"  class="${this.multiLevelHeaders ? 'py-1' : 'py-0'} truncate-text border-right border-bottom  border-top capitalize"> ${headersGroup.group} </th>`;
				}
			}
			this.multiLevelHeader.nativeElement.innerHTML = innerThead;
		}
	}	

}
