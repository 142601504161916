import { SurveyChart } from  '../chartJS/surveyChart';
import { Component, AfterViewInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';


@Component({
	selector: 'survey-chart',
	template: ` 
		<div id="containerChart"></div>
	`
})
export class SurveyChartComponent implements AfterViewInit, OnChanges{

	chart: any;

	@Input() data: any;
	@Output() clickEvent = new EventEmitter();
	
	@Input() sectionStatus: string;
	@Output() sectionStatusChange = new EventEmitter();

	ngOnChanges(change){
		if(change.sectionStatus.currentValue)
			this.onSectionStatusChange(change.sectionStatus.currentValue);
	}
 
	onSectionStatusChange(value){
		this.sectionStatus = value;
		this.chart[this.sectionStatus].open();
		this.sectionStatusChange.emit(this.sectionStatus);
	}

	constructor(){ }
	
	ngAfterViewInit(): void {
		setTimeout(() => {
			this.chart = SurveyChart('containerChart', this.data, (section) => this.toggle(this, this.clickEvent, section));
		}, 1000)
	}

	toggle(scope, event, section){
		scope.onSectionStatusChange(section);
		event.emit(section); 
	}

}