import { ChartConfig } from "../../models";

export class LineChartConfig {

	constructor() {}

	dateXConfig(){
		let chart = new ChartConfig();
		chart.chart = { type: 'line', backgroundColor: 'rgba(255, 255, 255, 0)'};
		chart.title = { text: null };
		chart.credits = { enabled: false };
		chart.exporting = { enabled: false };
		chart.legend = { 
			enabled: true,
			verticalAlign: 'top',
			symbolHeight: 12,
			symbolWidth: 12,
			symbolRadius: 0,
		};
		chart.tooltip = {
			headerFormat: '{point.x:%d/%m/%y}<br>',
			shared: true
		}
		chart.xAxis = {
			type: 'datetime', 
			tickInterval: 24 * 3600 * 1000,
			dateTimeLabelFormats: { day: '%d/%m/%y' },
			labels: {rotation: -70},
		}
		return chart;
	}

}