import { ChartConfig } from "../../models";

export class MasterDetailChartConfig {

	constructor() {}

	getConfig(height: string){
		let chart = new ChartConfig();
		chart.alignTicks = false;
		chart.chart = { height }
		chart.exporting = {enabled: true}
		chart.credits = {enabled: false}
		chart.rangeSelector = { selected: 1 }
		chart.legend = {
			enabled: true,
			labelFormatter:  function() {
				return `<div style="font-size: 0.7rem">${this.name}</div>`;
			},
			symbolHeight: 7,
			symbolWidth: 7,
			symbolRadius: 0,
		}
		chart.xAxis = {
			labels: {
				formatter: function() {
					const date =  new Date(this.value);
					return `<div style="font-size: 0.7rem">${date.getDate()}/${date.getMonth()}/${date.getFullYear()}</div>`;
				}
			}
		}
		return chart;
	}

}