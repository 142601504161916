import { NgModule } from '@angular/core';
import { ComponentsComponent } from './components.component';
import { MatSidenavModule, MatToolbarModule, MatButtonModule } from '@angular/material';
import { Menu42LabComponent } from '../menu/menu.component';
import { TitleBar42LabComponent } from '../titlebar/titlebar.component';

@NgModule({
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule
  ],
  declarations: [ComponentsComponent, Menu42LabComponent, TitleBar42LabComponent],
  exports: [ComponentsComponent, MatSidenavModule, Menu42LabComponent, MatToolbarModule, TitleBar42LabComponent, MatButtonModule]
})
export class Components42Module { }
