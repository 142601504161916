import { Component, Input } from '@angular/core';

@Component({
	selector: 'lab-titlebar',
	template: `<mat-toolbar class="title-bar">

	<div class="title-bar-header">
		<ng-content select="[leftContainer]"></ng-content>
	</div>
	
	<div class="title-bar-container">
		<ng-content select="[rightContainer]" class="title-bar-container"></ng-content>
	</div>

</mat-toolbar>

<style>


</style>` 
})
export class TitleBar42LabComponent {
	
	constructor() { }
	
}
