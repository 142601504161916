import { Component, Input } from '@angular/core';
import { HighchartsBaseComponent } from '../base/highcharts-base.component';
import { ActivityChartConfig } from '../configs/activitychartConfig.model';

@Component({
	selector: 'lab-activity',
	template: `
		<lab-highcharts>
			<div content class="chart" id="{{uuid}}" #chartView></div>
		</lab-highcharts>
	`
})
export class ActivityChartComponent extends HighchartsBaseComponent {

	@Input() colors: Array<string> = ['#d3d3d3', '#ddd'];

	constructor() {
		super();
	}

	ngAfterViewInit(){
		this.config = this.config ? {...this.config} : new ActivityChartConfig().getDoubleRoundConfig(this.colors[0], this.colors[1]);
		super.ngAfterViewInit();
	}

}
