import { Component, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { GroupDescriptor } from "../../../models/table";

@Component({
	selector: 'lab-table-config',
	template: `<div class="table-config shadow bg-white">

	<div class="container h-100 pt-1 mw-100 position-relative bg-white">

		<div class="row">
			<div class="col-12 pl-4 pt-4">
				<div (click)="closing()" class="text-center lab-button border border-success text-success p-1 d-inline-block px-3">
					Aplicar
				</div>
			</div>
		</div>
			
		<div class="row p-4"  *ngIf="groupedHeaders">
			<div class="col">
				<lab-table-config-column [groupHeaders]="headerColumns[0]">
				</lab-table-config-column>
			</div>
			<div class="col">
				<lab-table-config-column [groupHeaders]="headerColumns[1]">
				</lab-table-config-column>
			</div>
			<div class="col">
				<lab-table-config-column  [groupHeaders]="headerColumns[2]">
				</lab-table-config-column>
			</div>
			<div class="col">
				<lab-table-config-column  [groupHeaders]="headerColumns[3]">
				</lab-table-config-column>
			</div>
			
			<div class="col-12 text-left align-self-end">
				<span class="d-inline-block small">
					{{getAmountOfOnUseHeaders().onUseHeaders}} / {{getAmountOfOnUseHeaders().totalHeaders}} Selecionados
				</span>
			</div>

		</div>

	</div>
</div>
`
})
export class TableConfigComponent<T> implements AfterViewInit{
	
	@Input() filterSearch: string;
	@Input() groupedHeaders: Array<GroupDescriptor<T>> = [];
	
	@Input() isOpened = false;
	@Output() isOpenedChange = new EventEmitter<boolean>();

	totalHeaders = 0;
	headerColumns = [[], [], [], []];

	constructor(private cdr: ChangeDetectorRef){
	}

	closing(){
		this.isOpened = false;
		this.isOpenedChange.emit(this.isOpened);
	}
	
	getHeadersGroups(){
		this.groupedHeaders.filter(x =>{
			if(this.groupedHeaders.indexOf(x) % 4 == 0)
				this.headerColumns[0].push(x);

			if(this.groupedHeaders.indexOf(x) % 4 == 1)
				this.headerColumns[1].push(x);

			if(this.groupedHeaders.indexOf(x) % 4 == 2)
				this.headerColumns[2].push(x);
			
			if(this.groupedHeaders.indexOf(x) % 4 == 3)
				this.headerColumns[3].push(x);
		});
	}

	ngAfterViewInit(){ 
		this.getHeadersGroups(); 
		this.cdr.detectChanges()
	}

	getAmountOfOnUseHeaders(){
		let totalHeaders = 0;
		let onUseHeaders = 0;
		this.groupedHeaders.map(g => {
			onUseHeaders += g.headers.map(h => h.onUse || h.fixed).length;
			totalHeaders += g.headers.length;
		});
		return {totalHeaders, onUseHeaders}
	}

}