import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-form',
  template: `
    <p>
      form works!
    </p>
  `,
  styles: []
})
export class FormComponent implements OnInit {

  constructor() { }

  ngOnInit() {

}

}
