import { OnInit, Input } from '@angular/core';
import { Entity } from '../core';
import { HeaderDescriptor } from './header-descriptor';
import { GroupDescriptor } from './group-descriptor';

export abstract class TableListBase<T extends Entity> implements OnInit {

	@Input() entities: T[];
	@Input() excluding: boolean = false;
	headers: Array<HeaderDescriptor<T>> = [];
	groupHeaders: Array<GroupDescriptor<T>> = [];
	selectedEntities: Array<T> = new Array<T>();

	constructor() {}

	ngOnInit() { }
	
	filter(str: string): Array<T>{ return str ? this.entities.filterBy(str, this.headers) : this.entities; }

	buildHeaders() {}

	addGroupTableHeader(groupedHeaders: GroupDescriptor<T>){
		this.groupHeaders.push(groupedHeaders);
	}
	
	addTableHeader(name: string, getter: (entity: T) => any,  setter: <T>(entity: T, obj: any) => any, onUse: boolean, description: string, editable: any = false, fixed = false, click = null, icon:  { withDesc: boolean } = null) {
		const header = new HeaderDescriptor<T>({
			name: name, 
			getter: getter,  
			setter: setter, 
			onUse: onUse,
			description: description, 
			editable: editable, 
			fixed: fixed, 
			click: click, 
			icon: icon
		});
		this.headers.push(header);
		return header;
	}
	
	getObjectName(obj: any): string { return this.checkIfIsObject(obj) ? obj.name : obj; }

	checkIfIsObject(obj: any): boolean { return obj && obj.name }
}
