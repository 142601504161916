import { Observable } from 'rxjs';
import { Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { ListComponentBase } from './list-component-base';
import { BaseCRUDService } from '../base';
import { OperationResponse } from '../operation';
import { Entity, Concept } from '../core';

export abstract class CreateEditComponentBase<T extends Entity> implements OnInit  {
	
	@Input() list: ListComponentBase<T>;
	entity: T;
	concept: Concept;
	formGroup: FormGroup;
	protected abstract formBuilder: FormBuilder;

	constructor(public service: BaseCRUDService<T>) {
	}

	protected abstract getNew(): T;
	protected abstract createForm(): void;

	ngOnInit(): void {
		this.concept = this.list.concept;
	}

	save(): Observable<OperationResponse<T>> {
		const observable = new Observable<OperationResponse<T>>(observer => {
			this
				.service
				.save(this.entity)
				.subscribe(result => {
					if (result.realized) {
						this.entity = result.entity;
						this.list.list().subscribe();
					}
					observer.next(result);
				});
		});
		return observable;
	}

	close() {
		this.formGroup.reset();
		this.entity = null;
	}
	openNew() {
		this.entity = this.getNew();
		this.list.list().subscribe();
	}

	openById(id: number) {
		this.service.byID(id).subscribe(result => this.entity = result);
	}

}
