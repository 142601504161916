import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
	transform(text: string, search = ''): string {
		if(!text) return text;
		text = this.isString(text) ? text : text.toString();
		let pattern = search.noSpecials().replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
		pattern = pattern.split(' ').filter((t) => {
			return t.length > 0;
		}).join('|');
		const regex = new RegExp(pattern, 'gi');

		return search ? (text.noSpecials().replace(regex, (match) => `<span class="text-dark bold">${match}</span>`)) : text;
	}

	isString(text: any) {
		return (typeof text === 'string');
	}



}
