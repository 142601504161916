import { Component, Input } from '@angular/core';

@Component({
	selector: 'lab-highcharts',
	template: `
		<div class="chart-box">
			<div class="chart-title" *ngIf="!title">
				<h4> {{title}} </h4>
			</div>
			<div class="chart-content">
				<ng-content select="[content]"></ng-content>
			</div>
		</div>
	`
})
export class HighchartsComponent {
	@Input() title: string;
}
