export class ColumnModel {

	public index: number;
	public name: string;
	public value: string;
	public valueIsNumber: boolean;
	public setter: (...args: Array<any>) => any;
	public onUse:  boolean;
	public description: string;
	public editable: boolean;
	public typeOfEditon: 'text' | 'number' | 'boolean';
	public typeOfEditonIsNumber: boolean;	
	public fixed?: boolean;
	public click?: (...args: Array<any>) => {};
	public icon?: { withDesc: boolean };
	public alignment?:  'left' | 'center' | 'right';

	constructor() {}
}