import { Input,  AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { BaseConfig } from './base-config';
import { StockChart } from 'angular-highcharts';
import { ChartConfig } from '../../models/chart/highcharts';
import { ChartData } from '../../models/chart/chartdata';

export abstract class StockChartsBaseComponent implements AfterViewInit {

	public uuid: string;
	public chart: Highcharts.ChartObject;
	public baseConfig = new BaseConfig();

	@Input() public config: ChartConfig = null;
	@Input() public data: Array<Array<ChartData>> = null;
	@Input() public dataSource: Observable<Array<Array<ChartData>>> = null;

	constructor() { this.uuid = Math.random()+'asdfe'; }

	ngAfterViewInit(){ this.reloadData(); }
 
	public baseOption(): any { 	return this.config !== undefined && this.config !== null ? this.config : new ChartConfig(); }

	protected createChart(): any{
		return new StockChart(this.setOptions(this.data));
	}

	protected setOptions(data: Array<Array<ChartData>>): Highcharts.Options {
		const options = this.baseOption();
		options.series = this.setSeries(data);
		options.colors = this.baseConfig.colors;
		return options;
	}

	protected setSeries(series: Array<Array<ChartData>>): any {
		return <Array<Highcharts.IndividualSeriesOptions>> series;
	}

	protected reloadData(): void {
		if(this.data){
			setTimeout(() => {
				this.chart = this.createChart();
			}, 100)
		} else if (this.dataSource) {
			this.dataSource.subscribe((data) => {
					this.data = data;
					this.chart = this.createChart();
			});
		}
	}


}
