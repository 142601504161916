export class Concept {
	constructor(
			public key: string,
			public icon: string,
			public route: string,
			public controller?: string,
			public editable?: boolean,
			public name?: string,
			public pluralName?: string
	) {}
}
