import { Component, Input, ViewChild, AfterViewInit, ElementRef, EventEmitter, Output, OnInit } from '@angular/core';
import { ValueAccessorBase } from '../base';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';


@Component({
	selector: 'lab-text',
	template: `
<lab-input-base [deactivateLabelAnimation]="deactivateLabelAnimation" [whiteInput]="whiteInput" [id]="id" [input]="this" [label]="label" [iconStart]="iconStart" [iconEnd]="iconEnd" [description]="description" [disabled]="disabled">
	<input
		input
		#input
		mdbInputDirective
		type="text"
		id="{{id}}{{label}}"
		class="form-control input-padding"
		autocomplete="off"
		[disabled]="disabled"
		[validateError]="false"
		[validateSuccess]="false"
		[autofocus]="autofocus"
		[(ngModel)]="value"
		kzMask="{{mask ? mask : '' }}"
		[ngClass]="{
			'border-light': disabled,
			'text-white': !disabled && whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'border-white': !disabled && whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'text-dark': !disabled && !whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'border-dark': !disabled && !whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'shadow-dark': !disabled && (isFormControlValid() && formControl.pristine) && focused,
			'text-info': !disabled && (!isFormControlValid() && inputHasFocus()) || (inputHasFocus() && isFormControlValid() && formControl.pristine),
			'border-info': !disabled && (!isFormControlValid() && inputHasFocus()) || (inputHasFocus() && isFormControlValid() && formControl.pristine),
			'shadow-info': !disabled && (!isFormControlValid() && inputHasFocus()) || (inputHasFocus() && isFormControlValid() && formControl.pristine),
			'text-success': !disabled && isFormControlValid() && formControl.status === 'VALID' && !formControl.pristine,
			'border-success': !disabled && isFormControlValid() && formControl.status === 'VALID' && !formControl.pristine,
			'shadow-success': !disabled && isFormControlValid() && formControl.status === 'VALID' && focused && !formControl.pristine,
			'text-danger': !disabled && isFormControlValid() && formControl.status === 'INVALID' && !formControl.pristine,
			'border-danger': !disabled && isFormControlValid() && formControl.status === 'INVALID' && !formControl.pristine,
			'shadow-danger': !disabled && isFormControlValid() && formControl.status === 'INVALID' && focused && !formControl.pristine
		}"
	>
	<div label-icons>
		<ng-content></ng-content>
	</div>
</lab-input-base> `,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: TextComponent, multi: true}
	]
})
export class TextComponent extends ValueAccessorBase<string> implements AfterViewInit{
	
	focused: boolean = false;
	@Input() label: string;
	@Input() placeholder: string;
	@Input() labelAlwaysActivated: boolean;
	@Input() iconEnd: string;
	@Input() whiteInput: boolean = false;
	@Input() deactivateLabelAnimation: boolean = false;
	@Input() iconStart: string;
	@Input() autofocus = false;
	@Input() description: string = null;
	@Input() formControl: FormControl = null;
	@Input() disabled: boolean = false;
	@Input() mask: boolean;
	@ViewChild('input') input: ElementRef;

	id: string;

	constructor() {
		super();
		this.id = Math.random()+'id';
	}

	ngAfterViewInit(){
		if(this.input){
			this.input.nativeElement.onfocus = () => {this.focused = true;}
			this.input.nativeElement.onblur = () => {this.focused = false;}
			
			if(this.labelAlwaysActivated || this.placeholder)
				this.input.nativeElement.placeholder = this.labelAlwaysActivated ? ' ' : this.placeholder;
		}
	}

	public isFormControlValid(): boolean { return this.formControl !== null && this.formControl !== undefined; }

	public isFormControlValidated(): boolean { return this.formControl.status === 'VALID'; }

	public inputHasFocus(): boolean { return this.focused; }

}