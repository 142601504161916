var SurveyChart = function(container, values, clickEvent){

	let firstSection;
	let secondSection;
	let thirdSection;
	let fourthSection;
	let mainSection;

	function initializeSections(){
		firstSection = {
			arc: document.getElementById("first-arc"),
			circle: document.getElementById("first-circle"),
			text: document.getElementById("first-text"),
			close: function(){
				this.arc.classList.remove('hovered')
				this.arc.classList.remove('openedFirstArc')
				this.circle.classList.remove('openedFirstCircle')
				this.text.classList.remove('openedFirstCircle')
			},
			open: function(){
				this.arc.classList.add('hovered');
				this.arc.classList.add('openedFirstArc')
				this.circle.classList.add('openedFirstCircle')
				this.text.classList.add('openedFirstCircle')
				toggleOthers(firstSection);
			},
			toggle: function() {
				if(!firstSection.arc.classList.contains('hovered')){
					firstSection.open();
				} else {
					firstSection.close();			
				}
				clickEvent('firstSection');
				toggleOthers(firstSection);
			}
		}
		
		secondSection = {
			arc: document.getElementById("second-arc"),
			circle: document.getElementById("second-circle"),
			text: document.getElementById("second-text"),
			close: function(){
				this.arc.classList.remove('hovered')
				this.arc.classList.remove('openedSecondArc')
				this.circle.classList.remove('openedSecondCircle')
				this.text.classList.remove('openedSecondCircle')
			},
			open: function(){
				this.arc.classList.add('hovered');
				this.arc.classList.add('openedSecondArc')
				this.circle.classList.add('openedSecondCircle')
				this.text.classList.add('openedSecondCircle')
				toggleOthers(secondSection);
			},
			toggle: function() {
				if(!secondSection.arc.classList.contains('hovered')){
					secondSection.open();
				} else {
					secondSection.close();			
				}
				clickEvent('secondSection');
				toggleOthers(secondSection);
			}
		}
		
		thirdSection = {
			arc: document.getElementById("third-arc"),
			circle: document.getElementById("third-circle"),
			text: document.getElementById("third-text"),
			close: function(){
				this.arc.classList.remove('hovered')
				this.arc.classList.remove('openedThirdArc')
				this.circle.classList.remove('openedThirdCircle')
				this.text.classList.remove('openedThirdCircle')
			},
			open: function(){
				this.arc.classList.add('hovered');
				this.arc.classList.add('openedThirdArc')
				this.circle.classList.add('openedThirdCircle')
				this.text.classList.add('openedThirdCircle')
				toggleOthers(thirdSection);
				
			},
			toggle: function() {
				if(!thirdSection.arc.classList.contains('hovered')){
					thirdSection.open();
				} else {
					thirdSection.close();			
				}
				clickEvent('thirdSection');
				toggleOthers(thirdSection);
			}
		}
		
		fourthSection = {
			arc: document.getElementById("fourth-arc"),
			circle: document.getElementById("fourth-circle"),
			text: document.getElementById("fourth-text"),
			close: function(){
				this.arc.classList.remove('hovered')
				this.arc.classList.remove('openedFourthArc')
				this.circle.classList.remove('openedFourthCircle')
				this.text.classList.remove('openedFourthCircle')
			},
			open: function(){
				this.arc.classList.add('hovered');
				this.arc.classList.add('openedFourthArc')
				this.circle.classList.add('openedFourthCircle')
				this.text.classList.add('openedFourthCircle')
				toggleOthers(fourthSection);
			},
			toggle: function() {
				if(!fourthSection.arc.classList.contains('hovered')){
					fourthSection.open();
				} else {
					fourthSection.close();			
				}
				clickEvent('fourthSection');
				toggleOthers(fourthSection);
			}
		}
		mainSection = document.getElementById('main-text');
	}
	
	//  vector-effect="non-scaling-stroke" ARC
	function HTML(){
		return `
		<div class="svg">
			<svg viewBox="-20 -25 130 160">

				<path d="M25.3,15.9
				A40,40 0 0,1 54.2,27.7"
				id="first-arc"/>
				<circle cx="45" cy="8" r="11" id="first-circle"/>
				<text x="39.5" y="10.5" id="first-text">0.0</text>

				<path d="M54.2,27.7
				A40,40 0 0,1 66,56"
				id="second-arc"/>
				<circle cx="76" cy="35" r="11" id="second-circle"/>
				<text x="70.5" y="37.5" id="second-text">0.0</text>

				<path d="M66,55.9
				A40,40 0 0,1 54.7,84"
				id="third-arc"/>
				<circle cx="78" cy="77" r="11" id="third-circle"/>
				<text x="72.5" y="79.5" id="third-text">0.0</text>

				<path d="M54.8,84
				A40,40 0 0,1 26,96"
				id="fourth-arc"/>
				<circle cx="49" cy="107" r="11" id="fourth-circle"/>
				<text x="43.5" y="109.5" id="fourth-text">0.0</text>

				<circle cx="25" cy="58" r="35" id="main-circle-outside-bordered" />
				<circle cx="25" cy="58" r="35" id="main-circle-outside" />
				<path id="main-circle-fill"/>
				<text x="5" y="67.5" id="main-text">0.0</text>
				
			</svg>
		</div>`
	}

	function initializeChart(){
		firstSection.arc.classList.add('stroke');
		firstSection.arc.classList.add('not-hovered');
	
		secondSection.arc.classList.add('stroke');
		secondSection.arc.classList.add('not-hovered');
	
		thirdSection.arc.classList.add('stroke');
		thirdSection.arc.classList.add('not-hovered');
	
		fourthSection.arc.classList.add('stroke');
		fourthSection.arc.classList.add('not-hovered');
	
		firstSection.text.innerHTML = values.first;
		secondSection.text.innerHTML = values.second;
		thirdSection.text.innerHTML = values.third;
		fourthSection.text.innerHTML = values.fourth;
		mainSection.innerHTML = values.main;
	}
	
	function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
		var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
	
		return {
		x: centerX + (radius * Math.cos(angleInRadians)),
		y: centerY + (radius * Math.sin(angleInRadians))
		};
	}
	
	function describeArc(x, y, radius, startAngle, endAngle){
	
		var start = polarToCartesian(x, y, radius, endAngle);
		var end = polarToCartesian(x, y, radius, startAngle);
	
		var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
			var d = [
			"M", start.x, start.y, 
			"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
		].join(" ");
	
		return d;
	}
	
	
	
	function toggleOthers(section) {
		if(section !== firstSection) firstSection.close();
		if(section !== secondSection) secondSection.close();
		if(section !== thirdSection) thirdSection.close();
		if(section !== fourthSection) fourthSection.close();
	}
	
	function mapArcData(value){
		let min = 0;
		let max = 5;
		let maxMapped = 359.99999;
		return ((value * maxMapped) / max);
	}

	function startChart(){
		let divContainer = document.getElementById(container);
		divContainer.innerHTML = HTML();
		initializeSections();
		initializeChart();
		document.getElementById("main-circle-fill").setAttribute("d", describeArc(25, 58, 29, 0, mapArcData(+mainSection.innerHTML) ));
		firstSection.arc.onclick = firstSection.toggle;
		secondSection.arc.onclick = secondSection.toggle;
		thirdSection.arc.onclick = thirdSection.toggle;
		fourthSection.arc.onclick = fourthSection.toggle;
	}

	

	startChart();
	return {firstSection, secondSection, thirdSection, fourthSection, mainSection};
};

export { SurveyChart };
