import { ChartConfig } from "../../models";

export class ActivityChartConfig {

	constructor(){ }

	getDoubleRoundConfig(color1, color2){
		let chart: ChartConfig = new ChartConfig();
		chart.chart = { type: 'solidgauge', backgroundColor: 'rgba(255, 255, 255, 0)', height: '100%' };
		chart.title = { text: null };
		chart.credits = { enabled: false };
		chart.exporting = { enabled: false };
		chart.pane =  {
			startAngle: 0,
			endAngle: 360,
			background: [{
				outerRadius: '112%',
				innerRadius: '88%',
				backgroundColor: color1,
				borderWidth: 0
			}, {
				outerRadius: '87%',
				innerRadius: '63%',
				backgroundColor: color2,
				borderWidth: 0
			}]
		}
		chart.yAxis = {
			min: 0,
			max: 5,
			lineWidth: 0,
			tickPositions: []
		};
		chart.plotOptions = {
			solidgauge: {
				dataLabels: {
					enabled: false,
					borderWidth: 0
				},
				linecap: 'round',
				stickyTracking: false,
				rounded: true
			}
		};

		chart.tooltip =  {
			enabled: false
		};
		return chart;
	}


}