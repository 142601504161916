import { ChartConfig } from "../../models";

export class SpiderwebChartConfig {

	constructor() {}

	public getSpiderWebConfig(height: string): any {
		const chart = new ChartConfig();
		chart.chart = {type: 'line', backgroundColor: 'rgba(255, 255, 255, 0)', polar: true, height: height};
		chart.title = null;
		chart.credits = { enabled: false };
		chart['exporting'] = { enabled: false };
		chart.yAxis = {
			tickmarkPlacement: 'on',
			gridLineInterpolation: 'polygon',
			lineWidth: 0,
			min: 0,
			max: 5,
			gridLineColor: '#ddd',
			tickAmount: 3
		};
		chart.xAxis = {
			tickmarkPlacement: 'on',
			lineWidth: 0,
			gridLineColor: '#ddd',
			title: {
				text: null
			},
			labels: {
				useHTML: true,
				formatter: function () {
					return `<span class="cursor-pointer" style="font-size: .6rem;"> ${this.value} </span>`
				},
				
			}
		}
		chart.plotOptions = {
			labels: {
				cursor: 'pointer',
				events: {
					click: (v) => {
						null;
					}
				}
			},
			series: {
				cursor: 'pointer',
				enabled: false,
				point: {
					events: {
						click: (v) => {
							null;
						}
					}
				}

			},
			bar: {
				dataLabels: {
					enabled: false
				},
				linecap: 'round',
				stickyTracking: false,
			}
		};

		chart.tooltip =  { 
			shared: true,
			pointFormat: '<span style="color:{series.color}">{series.name}: <b> {point.y:,.0f} </b><br/>'

		};
		return chart;
	}
}