import { IndividualSeriesOptions, AxisOptions, PaneOptions,
	ChartOptions, CreditsOptions, TitleOptions, TooltipOptions, LegendOptions } from 'highcharts';

export class ChartConfig implements Highcharts.Options {

	defs: any;
	series: IndividualSeriesOptions[];
	chart: ChartOptions = { type: 'line'};
	credits: CreditsOptions;
	title: TitleOptions;
	pane: PaneOptions;
	yAxis: any;
	xAxis: AxisOptions;
	tooltip: TooltipOptions;
	colors: string[];
	legend: LegendOptions;
	plotOptions: any;
	exporting: {enabled: boolean};
	alignTicks: any;
	height: any;
	rangeSelector: any;

	constructor() {
		this.defs = {
			patterns: [{
				'id': 'custom-pattern',
				'path': {
					d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
					strokeWidth: 3
				}
			}]
		};
	}

}
