import { Component } from "@angular/core";
import { HighchartsBaseComponent } from "../base/highcharts-base.component";
import { PieChartConfig } from "../configs/pieChartConfig.model";

@Component({
	selector: 'lab-piechart',
	template: `
		<lab-highcharts>
			<div content id="{{uuid}}"></div>
		</lab-highcharts>
	`
})
export class PieChartComponent extends HighchartsBaseComponent {

	ngAfterViewInit(){
		this.config = this.config ? this.config : new PieChartConfig().getBasePieChartConfig();
		super.ngAfterViewInit();
	}



}
