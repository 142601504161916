interface Array<T> {
	pushOrRemove(entity: any);
	filterBy(str: string, headers: any[]);
	transformRequest(obj: T);
	Has(obj: T): boolean;
	HasID(obj: number): boolean;
	clear();
}

Array.prototype.pushOrRemove = function (entity: any){
	const index = this.indexOf(entity);
	index === -1 ? this.push(entity) : this.splice(index, 1);
};

Array.prototype.filterBy = function (str: string, headers: any[]){
	const filteredEntitys = [];
	for (const entity of this) {
		for (const header of headers) {
			let value = header.getter(entity);
			if(value){
				value = (typeof value !== 'string') ? value.toString() : value;
				if (value.noSpecials().toLowerCase().indexOf(str.noSpecials().toLowerCase()) >= 0) {
					filteredEntitys.push(entity);
					break;
				
				}
			}
		}
	}
	return filteredEntitys;
};


Array.prototype.Has = function<T>(obj: T) {
	return this.indexOf(obj) >= 0;
}

Array.prototype.HasID = function(obj: number) {
	return this.map(x => x.id).indexOf(obj) >= 0;
}