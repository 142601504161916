export class HeaderDescriptor<TEntity> {
	
	public name: string;
	public getter: <TValue>(entity: TEntity) => TValue;
	public setter: <TValue>(entity: TEntity, object: any) => any;
	public onUse:  boolean;
	public description: string;
	public editable: boolean;
	public fixed?: boolean;
	public click?: (...arg: Array<any>) => {};
	public icon?: { withDesc: boolean };
	public alignment: 'left' | 'center' | 'right';
	
	constructor(
		config : {
			name: string,
			getter: <TValue>(entity: TEntity) => TValue,
			setter: <TValue>(entity: TEntity, object: TValue) => void,
			onUse: boolean,
			description: string,
			editable: boolean,
			fixed: boolean,
			click: () => {},
			icon:  { withDesc: boolean },
			alignment: 'left' | 'center' | 'right'
		}
	) { this.startConfig(config) }

	startConfig(config){
		this.name = config.name;
		this.getter = config.getter;
		this.setter = config.setter;
		this.onUse = config.onUse;
		this.description = config.description;
		this.editable = config.editable;
		this.fixed = config.fixed;
		this.click = config.click;
		this.icon = config.icon;
		this.alignment = config.alignment;
	}

}