import { HighchartsBaseComponent } from '../base/highcharts-base.component';
import { Component } from '@angular/core';
import { BarChartConfig } from '../configs/barChartConfig.model';

@Component({
	selector: 'lab-barchart',
	template: `
		<lab-highcharts>
			<div content class="chart" id="{{uuid}}" #chartView></div>
		</lab-highcharts>
	`
})
export class BarChartComponent extends HighchartsBaseComponent {

	constructor() {
		super();
	}

	ngAfterViewInit(){
		this.config = this.config ? this.config : new BarChartConfig().getDoubleBarsConfig("100%", ['#d3d3d3', '#ddd']);
		super.ngAfterViewInit();
	}

}
