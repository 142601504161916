import { ControlValueAccessor, FormControl } from '@angular/forms';
import { ElementRef, Input } from '@angular/core';

export class ValueAccessorBase<T> implements ControlValueAccessor {
	
	@Input() public formControl: FormControl;

	public focused: boolean;
	public input: ElementRef;

	private innerValue: T = null;
	private _id: string;
	
	get value(): any { return this.innerValue; }

	set value(value: any) {
		if (this.innerValue !== value) {
			this.innerValue = value;
			
			this.changed.forEach(f => f(value));
		}
	}


	public get id(): string{ return this._id; }

	public set id(id: string) { this._id = `${id}${Math.floor(Math.random() * 100)}` }

	private changed = new Array<(value: T) => void>();

	private touched = new Array<() => void>();

	public touch() { this.touched.forEach(f => f()); }

	public writeValue(value: T) { this.innerValue = value; }

	public registerOnChange(fn: (value: T) => void) { this.changed.push(fn); }

	public registerOnTouched(fn: () => void) { this.touched.push(fn); }

	public isFormControlValid(): boolean { return this.formControl !== null && this.formControl !== undefined; }

	public isFormControlPristine(): boolean { return this.isFormControlValid() && this.formControl.pristine }

	public isFormControlValidated(): boolean { return this.formControl.status === 'VALID'; }

	public inputHasFocus(): boolean { return this.focused; }

	public isInputValid(value: string): boolean{ return value !== '' && value !== null && value !== undefined }

}
