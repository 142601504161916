import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TextComponent } from '../forms/inputs/text/text.component';
import { ComboComponent } from '../forms/inputs/combo/combo.component';

import { FormComponent } from './form.component';
import { MDBBootstrapModule, 
	MaterialChipsModule, 
	BadgeModule, 
	IconsModule, 
	WavesModule, 
	CardsFreeModule, 
	AutocompleteModule, 
	InputsModule, 
	SelectModule } from 'ng-uikit-pro-standard'
import { InputBaseComponent } from '../forms/inputs/base/input-base.component';
import { ToastrModule } from 'ngx-toastr';
import { TableComponent } from '../forms/table/table.component';
import { HighlightPipe } from '../forms/pipes/highlight.pipe';
import { KzMaskDirective } from '../forms/directives/mask.directive';
import { TableConfigComponent } from '../forms/table/config/table-config.component';
import { ConfigColumnComponent } from '../forms/table/config/config-column/config-column.component';
import { MultiComboComponent } from '../forms/inputs/multicombo/multi-combo.component';
import { NumberComponent } from '../forms/inputs/number/number.component';
import { PasswordComponent } from '../forms/inputs/password/password.component';
import '../models/extensions/array.extension';
import '../models/extensions/string.extensions';
import { AutoCompleterModule } from 'ng-uikit-pro-standard'
import { OutSideEventHandlerDirective } from '../forms/directives/runOutsideAngular.directive';
import { LazyForDirective } from '../forms/directives/lazyFor.directive';

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		MDBBootstrapModule.forRoot(),
		BadgeModule, 
		IconsModule, 
		WavesModule,
		CardsFreeModule,
		AutocompleteModule,
		InputsModule,
		SelectModule,
		MaterialChipsModule,
		AutoCompleterModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
			progressBar: true, 
			enableHtml: true
		})
	],
	declarations: [
		FormComponent,
		InputBaseComponent,
		TextComponent,
		NumberComponent,
		PasswordComponent,
		TableComponent,
		TableConfigComponent,
		HighlightPipe,
		ConfigColumnComponent,
		ComboComponent,
		MultiComboComponent,
		TableComponent,
		HighlightPipe,
		KzMaskDirective,
		OutSideEventHandlerDirective,
		LazyForDirective
	],
	providers:[
	],
	exports: [
		FormComponent,
		InputBaseComponent,
		NumberComponent,
		PasswordComponent,
		TextComponent,
		TableComponent,
		TableConfigComponent,
		HighlightPipe,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		ComboComponent,
		MultiComboComponent

	]
})
export class FormModule { }
