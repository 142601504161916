import { ChartConfig } from "../../models";

export class GaugeChartConfig {

	getBaseGaugeConfig(){
		let chart = new ChartConfig();
		chart.chart =  {type: 'solidgauge' };
		chart.pane = {
			center: ['50%', '85%'],
			size: '100%',
			startAngle: -90,
			endAngle: 90,
			background: {
				backgroundColor: null,
				borderColor: null,
				shape: 'arc'
			}
		};
		chart.plotOptions = {
			solidgauge: {
				dataLabels: {
					y: 5,
					borderWidth: 0,
					useHTML: true
				}
			}
		};
		chart.yAxis = {
			min: 0,
			max: 100,
			lineWidth: 0,
			minorTickInterval: null,
			tickAmount: 2,
			title: {
				y: -70
			},
			labels: {
				y: 16
			}
		};
		return chart;
	}
}