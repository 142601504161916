import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { ComboBase } from '../base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Observable } from 'rxjs';
import { MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';

@Component({
	selector: 'lab-multi-combo',
	template: `<div class="md-form">
	<input
		#input
		mdbInputDirective
		id="{{id}}{{label}}"
		type="text"
		class="completer-input form-control mdb-autocomplete border-dark text-dark"
		[(ngModel)]="searchText"
		[disabled]="disabled"
		[ngClass]="{
			'border-light': disabled,
			'text-white': !disabled && whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'border-white': !disabled && whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'text-dark': !disabled && !whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'border-dark': !disabled && !whiteInput && ((!isFormControlValid() && !focused) || (isFormControlValid() && formControl.pristine && !inputHasFocus())),
			'shadow-dark': !disabled && (isFormControlValid() && formControl.pristine) && focused
		}"
		(input)="getFilteredData()" 
		(ngModelChange)="onChange($event)"
		[mdbAutoCompleter]="auto"
	>

	<label 
		for="{{id}}{{label}}" 
		[ngClass]="{
			'text-light': disabled,
			'text-dark': !disabled
		}"> {{label}} </label>

	<mdb-auto-completer #auto textNoResults="I have found no results :(" >
		<mdb-option *ngFor="let option of searchEntries(searchText) | async" [value]="option" > 
			<div class="row w-100 bg-transparent">
				<div class="col-lg-4 col-sm-5 bg-transparent" *ngIf="option && option.id && checkIdUsage(optionUsage)" style="font-size: 0.7rem">
					{{ option.id }}
				</div>
				<div class="col small truncate-text bg-transparent" *ngIf="option && option.name && checkNameUsage(optionUsage)" mdbTooltip="{{option.name}}" placement="top" container="body">
					{{ option.name }}
				</div>
			</div>
		</mdb-option>
	</mdb-auto-completer>

	<div style="max-height: 400px; overflow-y: auto;overflow-x: hidden;">
		<div class="chip h-100" [ngClass]="{'w-100': fullWidthTag }" *ngFor="let item of value">
			<div class="row align-items-center">
				<div class="col-md-1 col-sm-2" *ngIf="item && item.icon">
					<i class="fa {{item && item.icon}}"></i>  
				</div>
				<div class="col small truncate-text max-width-tooltip" mdbTooltip="{{item && item.name}}" placement="top" container="body">
					{{checkIdUsage(tagUsage) && item.id ? item.id : ''}} 
					{{tagUsage === 'both' && item && item.name && item.id ? ' - ' : ''}}
					{{checkNameUsage(tagUsage) && item && item.name ? item.name : ''}}
				</div>
				<div class="col-md-1 col-sm-2">
					<i class="close fa fa-times" (click)="remove(item)"></i>
				</div>
			</div>
		</div>
	</div>

</div>
`,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: MultiComboComponent, multi: true}
	]
})
export class MultiComboComponent<T> extends ComboBase<any> {
	
	amountToShow = 10;

	@Input() source: any;
	@Input() label: string;
	@Input() iconEnd: string;
	@Input() whiteInput: boolean = false;
	@Input() deactivateLabelAnimation: boolean = false;
	@Input() iconStart: string;
	@Input() autofocus = false;
	@Input() description: string = null;
	@Input() disabled: boolean = false;
	@Input() optionUsage: 'id' | 'name' | 'both' = 'both'
	@Input() tagUsage: 'id' | 'name' | 'both' = 'both'
	@Input() fullWidthTag: boolean = false;

	@ViewChild(MdbAutoCompleterComponent) completer: MdbAutoCompleterComponent;
	@ViewChild('input') input: ElementRef;

	constructor() { super(); }

	checkIdUsage(prop: 'id' | 'name' | 'both'){ return prop === 'id' || prop === 'both' }

	checkNameUsage(prop: 'id' | 'name' | 'both'){ return prop === 'name' || prop === 'both' }

	ngAfterViewInit(){this.subscribeToOptionSelected()}
	
	subscribeToOptionSelected(): void{
		this.completer.selectedItemChanged().subscribe((data: any) => {
			if(data.text) {
				let item = null;
				this.searchText = data.text;
				
				this.source.map(x => {
					if(this.checkSelectedItems(x, data.text)){
						item = x;
						return;	
					}
				});
				
				if (this.value == null || !(this.value instanceof Array))
					this.value = new Array<T>();

				this.value.push(item);
			}

			this.getFilteredData();
			this.clearInput();
		});
	}

	clearInput(){
		this.input.nativeElement.value = '';
		this.searchText = '';
		this.input.nativeElement.focus();
	}

	onChange(change) {
		let changes = change.split(' ').filter(y => y !== '' && !Number.isNaN(+y)).map(x => +x);
		
		if(changes.length > 1) {
			this.getEntryWithIDs(this.getDataItems(), changes).map((change) => {
				this.completer.setSelectedItem({text: change, element: null});
			});
			this.clearInput();
		}

		this.getFilteredData(); 
	}
	 
	getEntryWithIDs(data: Array<any>, ids: Array<string>): Array<any>{
		let searchItemsWithoutSelected = this.value ? data.filter(x => this.value.indexOf(x) < 0 ) : data;
		return searchItemsWithoutSelected.filter(x => ids.indexOf(x.id) >= 0);
	}
	
	searchEntries(term: string): Observable<Array<T>> {
		let searchItemsWithoutSelected = null
		let searchItems = super.searchEntries(term);

		if(!this.disabled){
			
			if(this.value && this.value.length > 0)
			searchItems.subscribe((data: Array<T>) => {
				searchItemsWithoutSelected = data.filter(x => this.value.indexOf(x) < 0 );
			});
		}
		
		return searchItemsWithoutSelected !== null && searchItemsWithoutSelected !== undefined ? of(searchItemsWithoutSelected) : searchItems;
	}

	remove(option: T): void {
		const index = this.value.indexOf(option);
		if (index >= 0) {
			this.value.splice(index, 1);
		}
	}

	checkSelectedItems(item: {id: number, name: string}, seachedItem: {id: number, name: string}){
		return (!item.id && item.name && item.name === seachedItem.name) 
			|| (item.id && !item.name && item.id === seachedItem.id) 
			|| (item.name && item.id && item.name === seachedItem.name && item.id === seachedItem.id)
	}
	
}