import { ChartConfig } from "../../models";

export class BarChartConfig {

	constructor() {}

	public getDoubleBarsConfig(height: string, colors: Array<string>): ChartConfig {
		let chart = new ChartConfig();
		chart.chart = { type: 'bar', backgroundColor: 'rgba(255, 255, 255, 0)', height: height};
		chart.title = { text: null };
		chart.credits = { enabled: false };
		chart.exporting = { enabled: false };
		chart.legend = { 
			enabled: true,
			verticalAlign: 'top',
			symbolHeight: 12,
			symbolWidth: 12,
			symbolRadius: 0,
			
		},
		chart.tooltip =  { enabled: false };
		chart.colors = colors;

		chart.yAxis = {
			min: 0,
			max: 5,
			title: ''
		};
		chart.xAxis = {
			max:1,
			title: {
				text: null
			},
			labels: {
			enabled: false
			}
		}
		chart.plotOptions = {
			series: {
				enabled: false,
				events: {
					legendItemClick: function (e) {
						e.preventDefault();
					}
				} 
			},
			bar: {
				dataLabels: {
					enabled: false
				},
				linecap: 'round',
				stickyTracking: false,
				
			}
		};

		return chart;
	}
}