import { ColumnModel } from './column.model';

export class RowModel {

	id: any;
	hasChanges: boolean = false;
	isVisible: boolean = true;
	isSelected: boolean = false;
	columns: Array<ColumnModel> = [];

	constructor() {}
}