import { NgModule } from '@angular/core';
import { ChartsComponent } from './charts.component';
import { CommonModule } from '@angular/common';
import { ChartModule, HIGHCHARTS_MODULES  } from 'angular-highcharts';
import { LineChartComponent } from '../charts/types/linechart.component';
import { HighchartsComponent } from '../charts/base/highcharts.component';
import { GaugeChartComponent } from '../charts/types/gaugechart.component';
import { PieChartComponent } from '../charts/types/piechart.component';

import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as threeD from 'highcharts/highcharts-3d';
import * as solidGauge from 'highcharts/modules/solid-gauge';
import * as patternFill from 'highcharts/modules/pattern-fill';
import * as highstock from 'highcharts/modules/stock.src';

import '../models/extensions/array.extension';
import '../models/extensions/string.extensions';

import { SpiderWebChartComponent } from '../charts/types/spiderwebchart.component';
import { BarChartComponent } from '../charts/types/barchart.component';
import { ActivityChartComponent } from '../charts/types/activitychart.component';
import { SurveyChartComponent } from '../charts/types/survey-chart.component';
import { MultiChartComponent } from '../charts/types/multichart.component';
import { MasterDetailStockChartComponent } from '../charts/types/master-detail-stock.component';

export function HM (){ return [ more, exporting, threeD, solidGauge, patternFill, highstock]; }

//@dynamic
@NgModule({
	imports: [
		CommonModule,
		ChartModule
	],
	providers: [
		{ provide: HIGHCHARTS_MODULES, useFactory: HM  } 
	],
	declarations: [
		ChartsComponent,
		LineChartComponent,
		GaugeChartComponent,
		PieChartComponent,
		HighchartsComponent,
		SpiderWebChartComponent,
		ActivityChartComponent,
		BarChartComponent,
		MultiChartComponent,
		MasterDetailStockChartComponent,
		SurveyChartComponent
		],
	exports: [
		ChartModule, 
		ChartsComponent, 
		LineChartComponent, 
		GaugeChartComponent,
		PieChartComponent,
		MultiChartComponent,
		SpiderWebChartComponent,
		ActivityChartComponent,
		BarChartComponent,
		SurveyChartComponent,
		MasterDetailStockChartComponent
	]
})
export class ChartsModule { }
