import { ChartConfig } from "../../models";

export class PieChartConfig {

	getBasePieChartConfig(){
		const chart = new ChartConfig();
		chart.chart = { type: 'pie' };
		chart.title = {text: null};
		chart.credits = { enabled: false};
		return chart;
	}

	get3DBasePieChart(){
		const chart = new ChartConfig();
		chart.chart = { type: 'pie' };
		chart.chart.options3d = this._3DOptions();
		chart.plotOptions = this._3DPlotOptions();
		chart.title = {text: null};
		chart.credits = { enabled: false};
		return chart;
	}

	private _3DPlotOptions(): Highcharts.PlotOptions {
		const plot: Highcharts.PlotOptions = {
			pie : {
				allowPointSelect: true,
				depth: 35,
				slicedOffset: 40,
				startAngle: -125,
			}
		};
		return plot;
	}

	private _3DOptions(): Highcharts.ChartOptions3d {
		return {
			enabled: true,
			depth: 20,
			alpha: 15,
			beta: 0
		};
	}
}