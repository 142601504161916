import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ValueAccessorBase } from './value-accessor-base';

@Component({
	selector: 'lab-input-base',
	template: `<div class="md-form">
	<i [ngClass]="{
			'hasStartIcon': hasStartIcon(), 
			'iconRightSpace': hasEndIcon(),
			'text-white': shouldUseWhiteOnInput(),
			'text-light': shouldUseMutedOnInput(),
			'text-dark': shouldUseDarkOnInput(),
			'text-info': shouldUseInfoOnInput(),
			'text-success': shouldUseSuccessOnInput(),
			'text-danger': shouldUseDangerOnInput()
		}"
		class="fa prefix {{iconStart}} "
	></i>

	<ng-content select="[input]"></ng-content>

	<label
		#labelComponent
		[ngClass]="{
			'active': inputHasValue() || true,
			'text-white': shouldUseWhiteOnInput(),
			'text-light': shouldUseMutedOnInput(),
			'text-dark': shouldUseDarkOnInput(),
			'text-info': shouldUseInfoOnInput(),
			'text-success': shouldUseSuccessOnInput(),
			'text-danger': shouldUseDangerOnInput(),
			'd-none': deactivateLabelAnimation && (inputHasFocus() || inputHasValue()) 
		}"
		for="{{input.id}}"
		(click)="focusOnInput()"
		class="no-pointer-events active">

		<span class="d-inline-block cursor-pointer"> {{label}} </span>

		<div class="d-inline-block float-right position-relative">
			<div [ngClass]="{'fixed-position-label': !inputHasAnyValue(), 'pr-4': iconEnd}">
				<i 
					*ngIf="input.isFormControlValid() && !input.isFormControlPristine() && !input.isFormControlValidated()"
					class="fa fa-warning d-inline-block text-danger small cursor-pointer"
					container="body"
					mdbTooltip="{{getValidationErrors()}}" placement="top"
					></i>
				<div class="d-inline-block" [ngClass]="{'pl-3': iconEnd}">
					<ng-content select="[label-icons]"></ng-content>
				</div>
					
			</div>
		</div>

	</label>

	<i 
		[ngClass]="{
			'hasEndIcon': hasEndIcon(),
			'text-white': shouldUseWhiteOnInput(),
			'text-light': shouldUseMutedOnInput(),
			'text-dark': shouldUseDarkOnInput(),
			'text-info': shouldUseInfoOnInput(),
			'text-success': shouldUseSuccessOnInput(),
			'text-danger': shouldUseDangerOnInput()
		}"
		class="fa posfix {{iconEnd}} icon no-pointer-events text-right" ></i>

</div>`
})
export class InputBaseComponent implements OnInit, OnChanges{

	@Input() input: ValueAccessorBase<string>;

	@Input() whiteInput: boolean = false;
	@Input() deactivateLabelAnimation: boolean = false;
	@Input() label: string;
	@Input() iconEnd: string = null;
	@Input() iconStart: string = null;
	@Input() description: string = null;
	@Input() disabled: boolean = false;
	@Input() mask: string = null;

	@Input() errors: Array<{[key: string] : string}>
	@ViewChild('labelComponent') labelComponent: ElementRef;


	ngOnInit(): void {}

	ngOnChanges(changes): void {
		if(this.input.value)
			this.labelComponent.nativeElement.classList.add('active')
	}

	public getValidationErrors(): string {
		let text = '';
		if(this.input.isFormControlValid() && this.hasErrors() && this.errors)
			Object.keys(this.input.formControl.errors).forEach((key) => {
				text = this.errors[key];
			});
		return text;
	}

	public hasStartIcon(): boolean { return this.iconStart !== null && this.iconStart !== undefined && this.iconStart !== ''}
	
	public hasEndIcon(): boolean { return this.iconEnd !== null && this.iconEnd !== undefined && this.iconEnd !== ''}

	public focusOnInput(){ this.input.input.nativeElement.focus();  }

	public hasErrors(): boolean { return this.input.formControl.errors !== null && this.input.formControl.errors !== undefined; }

	public shouldUseDarkOnInput(): boolean { return !this.whiteInput && (!this.disabled && (!this.input.isFormControlValid() && !this.inputHasFocus()) || (this.input.isFormControlValid() && !this.inputHasFocus()) && this.input.formControl.pristine)}
	
	public shouldUseWhiteOnInput(): boolean { return this.whiteInput && (!this.disabled && (!this.input.isFormControlValid() && !this.inputHasFocus()) || (this.input.isFormControlValid() && !this.inputHasFocus()) && this.input.formControl.pristine)}

	public shouldUseInfoOnInput(): boolean { return !this.disabled && (!this.input.isFormControlValid() && this.inputHasFocus()) || (this.input.isFormControlValid() && this.inputHasFocus() && this.input.formControl.pristine)}

	public shouldUseSuccessOnInput(): boolean { return !this.disabled && this.input.isFormControlValid() && this.input.isFormControlValidated() && !this.input.formControl.pristine }

	public shouldUseDangerOnInput(): boolean { return !this.disabled && this.input.isFormControlValid() && !this.input.isFormControlValidated() && !this.input.formControl.pristine }

	public shouldUseMutedOnInput(): boolean { return this.disabled; }

	public inputHasAnyValue(): boolean { return this.input.isFormControlValid() &&  ( this.input.focused || (this.input.formControl.value && this.input.formControl.value.length > 0)) }

	public inputHasFocus(): boolean { return this.input.focused; }

	public inputHasValue(): boolean {  return this.input.input.nativeElement.value.length > 0; }

}

