import { Observable } from 'rxjs';
import { OnInit } from '@angular/core';
import { BaseCRUDService } from '../base';
import { Entity, Concept } from '../core';
import { TableListBase } from '../table/table-list-base';

export abstract class ListComponentBase<T extends Entity> extends TableListBase<T> implements OnInit {

	entitys: T[];
	selectedEntitys: Array<T> = new Array<T>();
	concept: Concept;

	constructor(public service: BaseCRUDService<T>) {
		super();
		this.concept = service.concept;
	}

	ngOnInit() { 
		this.buildHeaders();
		this.list().subscribe(); 
	}
	
	filter(str: string): Array<T>{
		return this.entitys.filterBy(str, this.headers);
	}
	list(): Observable<T[]> {
		return new Observable((observer) => {
			this.service.list().subscribe((result) => {
				this.entitys = result
				observer.next(this.entitys);
			});
		});
		
	}
	delete() {
		this.selectedEntitys.map(value => this.entitys.splice(this.entitys.indexOf(value), 1));

		this.service.deleteList(this.selectedEntitys.map(value => value.id)).subscribe(result => {
			this.selectedEntitys = [];
		}, () => {

		});
	}

	getObjectName(obj: any): string { return this.checkIfIsObject(obj) ? obj.name : obj; }

	checkIfIsObject(obj: any): boolean { return obj && obj.name }


}
