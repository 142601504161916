import { Component, Input } from "@angular/core";
import { HeaderDescriptor, GroupDescriptor } from "../../../../models";

@Component({
	selector: 'lab-table-config-column',
	template: `<div class="half-left-border rounded position-relative mb-3 py-3 capitalize w-100" *ngFor="let item of groupHeaders">

	<div class="position-absolute bg-white group-label small text-muted">
		{{item.group}}
	</div>

	<div class="pl-3 col-xs-12 w-100" >
		<div *ngFor="let header of item.headers">
			<div class="row align-items-center">

				<div class="col-1 text-center m-0 p-0 ml-2">
					<i [ngClass]="{'icon-lab-lock': header.fixed}" class="small text-muted"> </i>
				</div>

				<div class="col-10 p-0 m-0">
					<input type="checkbox">
				
					<div class="switch">
						<label class="small sm-switch mb-0 pb-0" [ngClass]="{'disabled': header.fixed}" >
							<input type="checkbox" [checked]="header.onUse || header.fixed" [disabled]="header.fixed" (click)="toggleHeader(header)">
							<span class="lever"></span>
							{{header.name}}
						</label>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</div>
`
})
export class ConfigColumnComponent<T>{

	@Input() groupHeaders: Array<GroupDescriptor<T>>;

	constructor(){}

	deactivateHeader(header:  HeaderDescriptor<T>){ header.onUse = false; }

	activateHeader(header:  HeaderDescriptor<T>){ header.onUse = true; }

	toggleHeader(header: HeaderDescriptor<T>) {
		if (!header.fixed) {
			if(header && header.onUse)
				this.deactivateHeader(header);
			else 
				this.activateHeader(header);
		}
	}

}