import { IndividualSeriesOptions, PlotOptions, AxisOptions, PaneOptions,
	Options, ChartOptions, CreditsOptions, TitleOptions, TooltipOptions, LegendOptions } from 'highcharts';

export class ChartConfig implements Highcharts.Options {

	series: IndividualSeriesOptions[];
	chart: ChartOptions;
	credits: CreditsOptions;
	title: TitleOptions;
	pane: PaneOptions;
	yAxis: AxisOptions;
	xAxis: AxisOptions;
	tooltip: TooltipOptions;
	colors: string[];
	legend: LegendOptions;
	plotOptions: PlotOptions;

	constructor() {}

}
