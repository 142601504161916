import { Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Observable } from 'rxjs';
import { BaseConfig } from './base-config';
import { ChartConfig } from '../../models/chart/highcharts';
import { ChartData } from '../../models/chart/chartdata';

export abstract class HighchartsBaseComponent implements OnInit, AfterViewInit {

	public uuid: string;
	public chart: Highcharts.ChartObject;
	public baseConfig = new BaseConfig();

	@Input() public config: ChartConfig = null;
	@Input() public data: Array<Array<ChartData>> = null;
	@Input() public dataSource: Observable<Array<Array<ChartData>>> = null;

	@ViewChild('chart') public chartView: ElementRef;

	constructor() { this.uuid = Math.random()+'asdfe'; }

	ngOnInit() {  }

	ngAfterViewInit(){ 
		this.reloadData();
	}
 
	public baseOption(): ChartConfig { 
		return this.config !== undefined && this.config !== null ? this.config : new ChartConfig(); 
	}

	protected createChart(): Highcharts.ChartObject {
		return Highcharts.chart(document.getElementById(this.uuid), this.setOptions(this.data));
	}

	protected setOptions(data: Array<Array<ChartData>>): Highcharts.Options {
		const options = this.baseOption();
		options.series = this.setSeries(data);
		options.colors = this.baseConfig.colors;
		return options;
	}

	protected setSeries(series: Array<Array<ChartData>>): Array<Highcharts.IndividualSeriesOptions> {
		return <Array<Highcharts.IndividualSeriesOptions>> series;
	}

	protected reloadData(): void {
		if(this.data){
			this.chart = this.createChart();
		} else if (this.dataSource) {
			this.dataSource.subscribe((data) => {
				this.data = data;
				this.chart = this.createChart();
			});
		}
	}


}
