import { Component } from "@angular/core";

@Component({
	selector: "lab-menu",
	styles: [],
	template: `<div>
	<div class="lab-menu__header">
		<ng-content select="[header]"></ng-content>

		<ng-content select="[photo]"></ng-content>

		<div class="lab-menu__header--content">
			<span class="lab-menu__header--title">
				<ng-content select="[title]"></ng-content>
			</span>
			<div class="lab-menu__header--subtitle">
				<small>
					<ng-content select="[subtitle]"></ng-content>
				</small>
			</div>
		</div>

	</div>

	<div class="lab-menu__content">
		<ng-content select="[content]"></ng-content>
	</div>

	<div class="lab-menu__footer">
		<ng-content select="[footer]"></ng-content>
	</div>
</div>` 
})
export class Menu42LabComponent{


	constructor() {}
}


