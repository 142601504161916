import { Component } from "@angular/core";
import { HighchartsBaseComponent } from "../base/highcharts-base.component";
import { GaugeChartConfig } from "../configs/gaugeChartConfig.model";

@Component({
	selector: 'lab-gaugechart',
	template: `
		<lab-highcharts>
			<div content id="{{uuid}}" #chartView></div>
		</lab-highcharts>
	`
})
export class GaugeChartComponent extends HighchartsBaseComponent {

	constructor() {
		super();
	}
	
	ngAfterViewInit(){
		this.config = this.config ? this.config : new GaugeChartConfig().getBaseGaugeConfig();
		super.ngAfterViewInit();
	}


}
