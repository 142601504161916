import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OperationResponse } from '../operation';
import { Concept } from '../core';

@Injectable()
export class BaseCRUDService<T> {
	apiUrl: string;
	concept: Concept;

	constructor(private http: HttpClient, concept: Concept) {
		this.concept = concept;
	}
	list (): Observable<T[]> {
		return this.http.get<T[]>(this.apiUrl);
	}
	byID (id: number): Observable<T> {
		return this.http.get<T>(this.apiUrl + '/' + id.toString(), { params: new HttpParams().set('id', id.toString())});
	}
	save(entity: T): Observable<OperationResponse<T>> {
		return this.http.post<OperationResponse<T>>(this.apiUrl, entity);
	}
	delete (id: number): Observable<OperationResponse<T>> {
		return this.http.delete<OperationResponse<T>>(this.apiUrl + '/' + id.toString());
	}
	deleteList (ids: number[]): Observable<OperationResponse<T>[]> {
		return this.http.request<OperationResponse<T>[]>('delete', this.apiUrl, {body: JSON.stringify(ids)});
	}
	getInAPI<TReturn>(method: string, params?: any): Observable<TReturn> {
		return this.http.get<TReturn>(this.apiUrl + '/' + method, { params: params });
	}
	buildUrl(baseUrl: string){
		this.apiUrl = `${baseUrl}/${this.concept.controller}`;
	}

}
