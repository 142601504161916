export class BaseConfig {

	public darkAlpha = 0.8;

	public colors = [
		`rgba(160, 160, 160, ${this.darkAlpha})`,
		`rgba(64, 145, 67, ${this.darkAlpha})`,
		`rgba(210, 0, 0, ${this.darkAlpha})`,
		`rgba(20, 89, 180, ${this.darkAlpha})`,

		`rgba(121, 85, 72, ${this.darkAlpha})`,
		`rgba(205, 220, 57, ${this.darkAlpha})`,
		`rgba(3, 169, 244, ${this.darkAlpha})`,
		`rgba(233, 30, 99, ${this.darkAlpha})`,

		`rgba(244, 67, 54, ${this.darkAlpha})`,
		`rgba(33, 150, 243, ${this.darkAlpha})`,
		`rgba(139, 195, 74, ${this.darkAlpha})`,
		`rgba(255, 87, 34, ${this.darkAlpha})`,

		`rgba(156, 39, 176, ${this.darkAlpha})`,
		`rgba(0, 188, 212, ${this.darkAlpha})`,
		`rgba(255, 235, 59, ${this.darkAlpha})`,
		`rgba(158, 158, 158, ${this.darkAlpha})`,

		`rgba(103, 58, 183, ${this.darkAlpha})`,
		`rgba(0, 150, 136, ${this.darkAlpha})`,
		`rgba(255, 193, 7, ${this.darkAlpha})`,
		`rgba(96, 125, 139, ${this.darkAlpha})`,

		`rgba(63, 81, 181, ${this.darkAlpha})`,
		`rgba(76, 175, 80, ${this.darkAlpha})`,
		`rgba(255, 152, 0, ${this.darkAlpha})`,

		`rgba(13, 71, 161, ${this.darkAlpha})`,
		`rgba(13, 71, 161, ${this.darkAlpha})`,
		`rgba(51, 105, 30, ${this.darkAlpha})`,
		`rgba(191, 54, 12, ${this.darkAlpha})`,

		`rgba(62, 39, 35, ${this.darkAlpha})`,
		`rgba(130, 119, 23, ${this.darkAlpha})`,
		`rgba(1, 87, 155, ${this.darkAlpha})`,
		`rgba(136, 14, 79, ${this.darkAlpha})`,

		`rgba(74, 20, 140, ${this.darkAlpha})`,
		`rgba(0, 96, 100, ${this.darkAlpha})`,
		`rgba(245, 127, 23, ${this.darkAlpha})`,
		`rgba(33, 33, 33, ${this.darkAlpha})`,

		`rgba(49, 27, 146, ${this.darkAlpha})`,
		`rgba(38, 50, 56, ${this.darkAlpha})`,
		`rgba(255, 111, 0, ${this.darkAlpha})`,
		`rgba(230, 81, 0, ${this.darkAlpha})`,

		`rgba(26, 35, 126, ${this.darkAlpha})`,
		`rgba(27, 94, 32, ${this.darkAlpha})`,
		`rgba(230, 81, 0, ${this.darkAlpha})`,
	];

}