import { Input, ViewChild, ElementRef } from '@angular/core';
import { ValueAccessorBase } from './value-accessor-base';
import { Observable } from 'rxjs';
import { MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';

export abstract class ComboBase<T> extends ValueAccessorBase<T> {

	results: any;
	searchText = '';
	disabled: boolean;
	@Input() label: string;
	@Input() source: any;
	
	abstract amountToShow;

	@ViewChild('input') input: ElementRef;
	@ViewChild(MdbAutoCompleterComponent) completer: MdbAutoCompleterComponent;

	constructor() {
		super();
		this.id = Math.random()+'id';
	}

	ngAfterViewInit() { this.subscribeToOptionSelected(); }
	
	getDataItems() { return this.source; }
	
	getFilteredData() { 
		if(this.getDataItems() && !this.disabled)
			this.results = this.searchEntries(this.searchText);
	}
	
	onChange(change: any) { this.getFilteredData();  }
	
	searchEntries(term: string): Observable<T>{
		return new Observable((observer) => {
			let filter = this.getDataItems() && term.length > 0 ? this.getDataItems().filter((data: any) =>
				(data && data.id && (''+data.id).includes(''+term)) 
			||	(data && data.name && data.name.toLowerCase().includes(''+term.toLowerCase())) ) : [];

			observer.next(
				term && term.length > 0 && this.getDataItems() && filter ? 
				filter.slice(0, this.amountToShow) : 
				term != null && term.length == 0 && this.getDataItems() ? this.getDataItems().slice(0, this.amountToShow) : []);
		})
	}

	subscribeToOptionSelected(): void {
		this.completer.selectedItemChanged().subscribe((data: any) => {
			if(data.text) {		
				this.searchText = data.text;
				this.source.map(x => {
					if(x.name === data.text || x.id === data.text)
						this.value = x;
				})
			}
			this.getFilteredData();
		});
	}
}
