import { Component } from "@angular/core";
import { HighchartsBaseComponent } from "../base/highcharts-base.component";

@Component({
	selector: 'lab-multichart',
	template: `
		<lab-highcharts>
			<div content class="chart" id="{{uuid}}" #chartView></div>
		</lab-highcharts>
	`
})
export class MultiChartComponent extends HighchartsBaseComponent{
	
	constructor(){
		super();
	}

}